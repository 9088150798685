<template>
  <v-dialog
    v-model="dialog"
    content-class="modal-window-container"
    data-cy="modal-window"
    @input="onModalChanged"
  >
    <template #activator="{ on }">
      <template v-if="hasButtonSlot">
        <slot
          name="activator-button"
          :on="on"
        />
      </template>
      <template v-else>
        <v-btn
          rounded
          depressed
          color="primary"
          class="ml-auto"
          data-cy="change-plan-modal"
          v-on="on"
        >
          {{ buttonName }}
        </v-btn>
      </template>
    </template>
    <v-card>
      <v-card-title class="d-flex flex-row flex-nowrap align-start justify-space-between">
        <div class="headline">
          {{ title }}
        </div>
        <v-icon
          data-cy="change-plan-modal-close"
          @click="onModalClose"
        >
          {{ closeIcon }}
        </v-icon>
      </v-card-title>
      <v-divider />
      <slot name="content" />
    </v-card>
  </v-dialog>
</template>

<script>
import { CLOSE_ICON } from '../../../constants/app'
export default {
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    buttonName: {
      type: String,
      default: 'Button'
    },
    hasButtonSlot: {
      type: Boolean,
      default: false
    },
    onModalChanged: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      dialog: false,
      closeIcon: CLOSE_ICON
    }
  },
  methods: {
    onModalClose: function () {
      this.dialog = false
      this.onModalChanged()
    }
  }
}
</script>
